import "./src/styles/style.css"
import "./src/styles/normalize.css"
import "fontsource-open-sans/400.css"
import "fontsource-open-sans/300.css"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
